import { connect } from 'react-redux';

import { settingsSelector } from '../behaviors/settings';
import { configSelector } from '../behaviors/configuration';
import { searchWidgetConfigSelector } from '../behaviors/search-widget';
import { getCustomerCode } from '../behaviors/customer-code';
import Home from '../home';

const mapStateToProps = (state) => {
  const settings = settingsSelector(state);
  const config = configSelector(state);
  const searchWidgetConfig = searchWidgetConfigSelector(state);
  const customerCode = getCustomerCode(state);

  return {
    settings,
    config,
    searchWidgetConfig,
    customerCode
  };
};

const HomeContainer = connect(mapStateToProps)(Home);

export default HomeContainer;
