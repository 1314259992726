import React from 'react';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import { Link } from 'react-router-dom';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _extend from 'lodash/extend';
import _kebabCase from 'lodash/kebabCase';
import querystring from 'querystring';

import {
  getQueryModsToDeleteAvailParams,
  searchCategoryDisplayDescriptor
} from './utils';

const messages = defineMessages({
  didyoumean: {
    id: 'noresults.didyoumean',
    description: 'Text for a spelling suggestion',
    defaultMessage: 'Did you mean {suggestion} in {categoryName}?'
  },
  suggestionsheader: {
    id: 'noresults.suggestionsheader',
    description: 'Header for search suggestions',
    defaultMessage: 'Suggestions based on your search:'
  },
  browsebycommonspecialties: {
    id: 'noresults.browsebycommonspecialties',
    description: 'Header for suggestions of common searches',
    defaultMessage: 'Browse by Common Specialties'
  },
  noresults: {
    id: 'noresults.noresults',
    description: "The server didn't return any results for the search",
    defaultMessage: 'No results match your search.'
  },
  trymodifyingquery: {
    id: 'noresults.trymodifyingquery',
    description:
      'No results were returned for the search, so try removing some filters or searching for something else',
    defaultMessage:
      'Please try removing filters or begin a new search for condition, name, term, etc....'
  }
});

function SuggestionLink({ suggestion, getUpdatedSearch, log }) {
  const logSuggestionSelected = () => {
    log('user_action.search_results.spell_suggestion_clicked', {
      suggestion: suggestion.display_text,
      suggestion_type: suggestion.display_search_type
    });
  };
  return (
    <Link
      to={getUpdatedSearch(suggestion.query_modifications)}
      className="fw-6 suggestions"
      data-action="suggestion"
      onClick={logSuggestionSelected}
    >
      {suggestion.display_text}
    </Link>
  );
}

function Suggestions({ suggestions, getUpdatedSearch, log }) {
  if (_isEmpty(suggestions)) {
    return null;
  } else {
    const suggestionsList = _map(suggestions, (suggestion, index) => {
      const suggestionLink = (
        <SuggestionLink
          suggestion={suggestion}
          getUpdatedSearch={getUpdatedSearch}
          log={log}
        />
      );
      const categoryDescriptor = searchCategoryDisplayDescriptor(
        suggestion.display_search_type
      );
      const category = <FormattedMessage {...categoryDescriptor} />;
      return (
        <li className="suggestion-message" key={index}>
          <FormattedMessage
            {...messages.didyoumean}
            values={{
              suggestion: suggestionLink,
              categoryName: category
            }}
          />
        </li>
      );
    });
    return (
      <ul className="list-m pa-m bc-soft">
        <h3 className="mb-s">
          <FormattedMessage {...messages.suggestionsheader} />
        </h3>
        {suggestionsList}
      </ul>
    );
  }
}

function TopSpecialtiesColumn({
  topSpecialties,
  getUpdatedSearch,
  log,
  history
}) {
  const specialtyList = _map(topSpecialties, (specialty) => {
    const logSpecialtySelected = () => {
      log('user_action.search_results.no_results_browse_clicked', {
        link: specialty.display_text,
        type: 'specialty'
      });
    };

    const modifications = [
      ...getQueryModsToDeleteAvailParams(
        querystring.parse(history.location.search.substring(1))
      )
    ];

    if (specialty.query_modifications) {
      modifications.push(...specialty.query_modifications);
    }

    const updatedSearch = getUpdatedSearch(modifications);

    return (
      <div className="pb-s no-wrap" key={specialty.display_text}>
        <Link
          to={updatedSearch}
          id={`top-specialty-${_kebabCase(specialty.display_text)}`}
          className="top-specialty"
          onClick={logSpecialtySelected}
        >
          <h3>{specialty.display_text}</h3>
        </Link>
      </div>
    );
  });
  return <div className="col-sm-6 col-xs-12">{specialtyList}</div>;
}

function TopSpecialties({ topSpecialties, getUpdatedSearch, log, history }) {
  if (_isEmpty(topSpecialties)) {
    return null;
  } else {
    const splitIndex = topSpecialties.length / 2;
    const col1 = topSpecialties.slice(0, splitIndex);
    const col2 = topSpecialties.slice(splitIndex);
    const logTopSpecialties = (message, event_data = {}) =>
      log(
        message,
        _extend(
          { all_links: { specialties: _map(topSpecialties, 'display_text') } },
          event_data
        )
      );
    return (
      <div id="top-specialties" className="top-specialties pa-m mb-m bc-soft">
        <h3 className="pb-s">
          <FormattedMessage {...messages.browsebycommonspecialties} />
        </h3>
        <div className="row">
          <TopSpecialtiesColumn
            topSpecialties={col1}
            getUpdatedSearch={getUpdatedSearch}
            log={logTopSpecialties}
            history={history}
          />
          <TopSpecialtiesColumn
            topSpecialties={col2}
            getUpdatedSearch={getUpdatedSearch}
            log={logTopSpecialties}
            history={history}
          />
        </div>
      </div>
    );
  }
}

function NoResults({
  suggestions,
  topSpecialties,
  getUpdatedSearch,
  searchQuery,
  log,
  history
}) {
  return (
    <div>
      <div className="no-results ma-xl">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2 className="mb-s">
                <FormattedMessage {...messages.noresults} />
              </h2>
              <h4 className="mb-l">
                <FormattedMessage {...messages.trymodifyingquery} />
              </h4>
              <Suggestions
                suggestions={suggestions}
                getUpdatedSearch={getUpdatedSearch}
                searchQuery={searchQuery}
                log={log}
              />
            </div>
          </div>
        </div>
      </div>
      <TopSpecialties
        topSpecialties={topSpecialties}
        getUpdatedSearch={getUpdatedSearch}
        log={log}
        history={history}
      />
    </div>
  );
}

export { NoResults, TopSpecialties, Suggestions };
export default NoResults;
